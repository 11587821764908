import { APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideRouter, withEnabledBlockingInitialNavigation } from '@angular/router';
import { NgUiKitModule } from '@infocert/ng-ui-kit';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { LoggerModule } from 'ngx-logger';
import { TofeLanguageModule } from 'tofe-language';
import { TofeSessionModule } from 'tofe-session';
import { ApiModule, Configuration, ConfigurationParameters } from 'top-api-sdk-angular';

import { AppComponent } from './app/app.component';
import { appRoutes } from './app/app.routes';
import { environment } from './environments/environment';

function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    basePath: environment.apiBaseUrl,
  };
  return new Configuration(params);
}

export const providers = [
  {
    provide: APP_BASE_HREF,
    useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
    deps: [PlatformLocation],
  },
  importProvidersFrom(
    TofeSessionModule.forRoot({
      apiBaseUrl: environment.apiBaseUrl,
    }),
  ),
  provideHttpClient(withInterceptorsFromDi()),
  importProvidersFrom(LoggerModule.forRoot(environment.logs.logger)),
  importProvidersFrom(ApiModule.forRoot(apiConfigFactory)),
  importProvidersFrom(NgUiKitModule.forRoot()),
  provideRouter(appRoutes, withEnabledBlockingInitialNavigation()),
  importProvidersFrom(
    TofeLanguageModule.forRoot({
      isViewTokenEnabled: environment.environment !== 'prod',
    }),
  ),
];

bootstrapApplication(AppComponent, {
  providers,
}).catch((err) => console.error(err));

// used for selfieid task
// see https://capacitorjs.com/docs/web/pwa-elements
void defineCustomElements(window);
